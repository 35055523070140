/* eslint-disable @typescript-eslint/camelcase */
import { openGraphConfig } from '@lib/lp-seo'
import { LinguiJSMessageDescriptor } from '@lib/types/common'
import { defineMessage } from '@lingui/macro'
import { NextSeoProps } from 'next-seo'

export const loginSeo = {
  title: defineMessage({
    id: 'metaTag.title.login',
    message:
      'Login to Codingal - Code Faster, Learn Faster | Coding for Kids & teens',
  }),
  description: defineMessage({
    id: 'metaTag.description.login',
    message:
      'Codingal is on a mission to provide coding education to every K-12 student. Login on Codingal and start learning to code.',
  }),
  ...openGraphConfig(
    'https://www.codingal.com/login/',
    defineMessage({
      id: 'metaTag.title.login',
      message:
        'Login to Codingal - Code Faster, Learn Faster | Coding for Kids & teens',
    }),
    defineMessage({
      id: 'metaTag.description.login',
    })
  ),
}

export const registerSeo = {
  title: defineMessage({
    id: 'metaTag.title.register',
    message:
      'Register with Codingal | Learn Online Coding Classes for Kids & Teens',
  }),
  description: defineMessage({
    id: 'metaTag.description.register',
    message:
      'Codingal is on a mission to provide coding education to every K-12 student. Register on Codingal today and start learning to code.',
  }),
  ...openGraphConfig<LinguiJSMessageDescriptor>(
    'https://www.codingal.com/register/',
    defineMessage({
      id: 'metaTag.title.register',
      message:
        'Register with Codingal | Learn Online Coding Classes for Kids & Teens',
    }),
    defineMessage({
      id: 'metaTag.description.register',
      message:
        'Codingal is on a mission to provide coding education to every K-12 student. Register on Codingal today and start learning to code.',
    })
  ),
}

export const verifyOtpSeo = {
  title: 'Verify OTP',
  description:
    'Codingal is on a mission to provide coding education to every K-12 student. Login on Codingal and start learning to code.',
  ...openGraphConfig(
    'https://www.codingal.com/verify-otp/',
    'Verify OTP',
    'Codingal is on a mission to provide coding education to every K-12 student. Login on Codingal and start learning to code.'
  ),
}

export const trialClassBookingSeo = {
  title: defineMessage({
    id: 'metaTag.title.trialClassBooking',
    message: 'Try a free trial class',
  }),
  ...openGraphConfig(
    'https://www.codingal.com/trial-class/booking/',
    defineMessage({
      id: 'metaTag.title.trialClassBooking',
      message: 'Try a free trial class',
    })
  ),
}

export const trialClassJoinSeo = {
  title: defineMessage({
    id: 'metaTag.title.trialClassJoin',
    message: 'Join your trial class',
  }),
  ...openGraphConfig(
    'https://www.codingal.com/trial-class/join/',
    defineMessage({
      id: 'metaTag.title.trialClassJoin',
      message: 'Join your trial class',
    })
  ),
}

export const trialClassCompleteSeo = {
  title: defineMessage({
    id: 'metaTag.title.trialClassComplete',
    message: 'Download your certificate',
  }),
  ...openGraphConfig(
    'https://www.codingal.com/trial-class/complete/',
    defineMessage({
      id: 'metaTag.title.trialClassComplete',
      message: 'Download your certificate',
    })
  ),
}

export const trialClassIncompleteSeo = {
  title: defineMessage({
    id: 'metaTag.title.trialClassIncomplete',
    message: 'Reschedule your trial class',
  }),
  ...openGraphConfig(
    'https://www.codingal.com/trial-class/incomplete/',
    defineMessage({
      id: 'metaTag.title.trialClassIncomplete',
      message: 'Reschedule your trial class',
    })
  ),
}

export const dashboardSeo = {
  title: defineMessage({ id: 'metaTag.title.dashboard', message: 'Dashboard' }),
  ...openGraphConfig(
    'https://www.codingal.com/dashboard/',
    defineMessage({ id: 'metaTag.title.dashboard', message: 'Dashboard' })
  ),
}

export const termsSeo = {
  title: 'Terms and conditions',
  description:
    'Codingal helps you learn coding. Our Terms of Service outlines the rules and regulations of using our website. Get started today and join our community of coders!',
  ...openGraphConfig(
    'https://www.codingal.com/terms/',
    'Terms and conditions',
    'Codingal helps you learn coding. Our Terms of Service outlines the rules and regulations of using our website. Get started today and join our community of coders!'
  ),
}

export const privacyPolicySeo = {
  title: 'Privacy Policy | Read Our Terms & Conditions',
  description:
    'This Privacy Policy explains how collects and uses your data, and how we protect your privacy when you use our services. Read it to understand how we handle your data.',
  ...openGraphConfig(
    'https://www.codingal.com/privacy-policy/',
    'Privacy Policy | Read Our Terms & Conditions',
    'This Privacy Policy explains how collects and uses your data, and how we protect your privacy when you use our services. Read it to understand how we handle your data.'
  ),
}

export const bookTrialClassLandingPageSeo = {
  title:
    'Try a free coding class for your child | Codingal is the #1 Coding Platform for kids',
  description:
    'Teach your child the fundamentals of coding. Your child can learn how to build animations, websites and mobile app from all-star teachers with computer science background. Book a class worth 799/- for free today.',
  ...openGraphConfig(
    'https://www.codingal.com/book-trial-class/',
    'Try a free coding class for your child | Codingal is the #1 Coding Platform for kids',
    'Teach your child the fundamentals of coding. Your child can learn how to build animations, websites and mobile app from all-star teachers with computer science background. Book a class worth 799/- for free today.',
    'https://fastly.codingal.com/images/book-trial-class/meta-image.png'
  ),
}

export const whyCodingalSeo = {
  title: 'Why Codingal: For online coding lessons for your child',
  description:
    'Best in class curriculum, teachers and student experience for online coding classes for kids on Codingal. Try a free lesson & class today.',
  ...openGraphConfig(
    'https://www.codingal.com/why-codingal/',
    'Why Codingal: For online coding lessons for your child',
    'Best in class curriculum, teachers and student experience for online coding classes for kids on Codingal. Try a free lesson & class today.'
  ),
}

export const curriculumSeo = {
  title: 'Coding Curriculum for K-12 Kids',
  description: `Codingal’s curriculum is based on principles of Bloom, STEAM, and BIDE built for different age groups.`,
  ...openGraphConfig(
    'https://www.codingal.com/curriculum/',
    'Coding Curriculum for K-12 Kids',
    `Codingal’s curriculum is based on principles of Bloom, STEAM, and BIDE built for different age groups.`
  ),
}

export const homePageSeo = {
  title: defineMessage({
    id: 'metaTag.title.home.1',
    message: 'Best Accredited Online Coding Courses for Kids & Teens',
  }),
  description: defineMessage({
    id: 'metaTag.description.home',
    message:
      'Codingal offers engaging & fun online coding & AI classes for kids & teens using the K12 Computer Science Framework. Sign up today to get a free class!',
  }),
  ...openGraphConfig<LinguiJSMessageDescriptor>(
    'https://www.codingal.com/',
    defineMessage({
      id: 'metaTag.title.home.2',
      message: 'Online Programming & Coding Classes For Kids',
    }),
    defineMessage({
      id: 'metaTag.description.home',
      message:
        ' Codingal offers kid-friendly programming and coding classes for kids and teens of all ages! Try a free coding class and start creating your own apps, websites, games, and much more.',
    })
  ),
}

export const aboutUsSeo = {
  title: 'About Us',
  description:
    'Codingal is the online coding platform for K-12 kids with a mission to help children fall in love with coding at a young age.',
  ...openGraphConfig(
    'https://www.codingal.com/about-us/',
    'About Us',
    'Codingal is the online coding platform for K-12 kids with a mission to help children fall in love with coding at a young age.'
  ),
}

export const codingalVsWhitehatjr = {
  title: 'Why Codingal is the best Whitehatjr alternative',
  description: `Codingal offers you the best online coding education overtaking Whitehatjr in parents reviews, children's engagement and course curriculum`,
  ...openGraphConfig(
    'https://www.codingal.com/codingal-vs-whitehatjr/',
    'Why Codingal is the best Whitehatjr alternative',
    `Codingal offers you the best online coding education overtaking Whitehatjr in parents reviews, children's engagement and course curriculum`
  ),
}

export const pressReleaseSeo = {
  title: 'News, Press & Media Coverage',
  description:
    'See the latest news, announcements, media coverage and press releases from Codingal.',
  ...openGraphConfig(
    'https://www.codingal.com/press/',
    'News, Press & Media Coverage',
    `See the latest news, announcements, media coverage and press releases from Codingal.`
  ),
}

export const teacherMentoringProgramSeo = {
  title:
    'Training Sessions in Scratch Programming for Teachers - A partner initiative with Shraasta & UpSkill',
  description: `Codingal partners with IIT-Madras & Shaastra'21 to conduct a training session in Scratch programming for teachers. This initiative is supported by UpSkill - a tech learning platform`,
  ...openGraphConfig(
    'https://www.codingal.com/shaastra-upskill-teacher-mentoring-program/',
    'Training Sessions in Scratch Programming for Teachers - A partner initiative with Shraasta & UpSkill',
    `Codingal partners with IIT-Madras & Shaastra'21 to conduct a training session in Scratch programming for teachers. This initiative is supported by UpSkill - a tech learning platform`
  ),
}

export const competitionsListSeo = {
  title: 'Coding challenges for school kids.',
  description:
    'These competitions are open to kids to show-off their coding skills. The challenges range from coding hackathons to programming competitions.',
  ...openGraphConfig(
    'https://www.codingal.com/competitions/',
    'Coding challenges for school kids.',
    'These competitions are open to kids to show-off their coding skills. The challenges range from coding hackathons to programming competitions.',
    'https://fastly.codingal.com/images/competitions/meta-image.png'
  ),
}

export const benefitsOfCodingLandingPageSeo = {
  title: 'Why should children learn coding?',
  description:
    'Discover the amazing benefits of coding and how it can help you become a better problem solver, innovator & develop creative thinking',
  ...openGraphConfig(
    'https://www.codingal.com/benefits-of-coding/',
    'Why should children learn coding?',
    'Discover the amazing benefits of coding and how it can help you become a better problem solver, innovator & develop creative thinking',
    'https://fastly.codingal.com/images/book-trial-class/meta-image.png'
  ),
}

export const schoolPartnershipPageSeo = {
  title:
    'Coding for schools I Teacher Training, Accredited Curriculum & LMS From Codingal',
  description:
    'Codingal School Partnership Program provides technology, training, and support to K-12 schools, so students have the skills to succeed in an increasingly digital world.',
  ...openGraphConfig(
    'https://www.codingal.com/school-partnership/',
    'Coding for schools I Teacher Training, Accredited Curriculum & LMS From Codingal',
    'Codingal School Partnership Program provides technology, training, and support to K-12 schools, so students have the skills to succeed in an increasingly digital world.'
  ),
}

export const referralPageSeo = {
  title: 'Earn INR 1000 for every referral - Partner Program',
  description:
    'Earn INR 1000 for every student that you refer who purchases a course on Codingal. K-12 students start learning to code via online coding classes conducted by best teachers and using an engaging curriculum.',
  ...openGraphConfig(
    'https://www.codingal.com/referral/',
    'Earn INR 1000 for every referral - Partner Program'
  ),
}

export const hpeReferralPageSeo = {
  title: 'Earn Amazon Voucher worth INR 100 and more',
  description:
    'Refer your friends & fellow-coders to register for HPE CodeWars 2021 and win INR 50 for every referrer that participates in the challenge',
  ...openGraphConfig(
    'https://www.codingal.com/hpe-referral/',
    'Earn Amazon Voucher worth INR 100 and more'
  ),
}

export const summerCampPageSeo = {
  title: 'Online Coding Summer Camp for Kids and Teens',
  description:
    'Attend a coding summer-camp from the comfort of your home! Learn how to make your own apps, games, websites. Check out the courses on offer and enroll today!',
  ...openGraphConfig(
    'https://www.codingal.com/summer-camp/',
    'Online Coding Summer Camp for Kids and Teens',
    'Attend a coding summer-camp from the comfort of your home! Learn how to make your own apps, games, websites. Check out the courses on offer and enroll today!',
    'https://fastly.codingal.com/images/summer-camp/meta-image-2022.jpg'
  ),
}

export const ramadanCampPageSeo = {
  title: 'Online Ramadan Coding Camp for Kids and Teens',
  description:
    'Attend a Ramadan coding camp from the comfort of your home! Learn how to make your own apps, games, websites. Check out the courses on offer and enroll today!',
  ...openGraphConfig(
    'https://www.codingal.com/ramadan-coding-camp/',
    'Online Ramadan Coding Camp for Kids and Teens',
    'Attend a ramadan coding camp from the comfort of your home! Learn how to make your own apps, games, websites. Check out the courses on offer and enroll today!',
    `${process.env.NEXT_PUBLIC_STATIC_URL}/images/book-trial-class/meta-image.png`
  ),
}

export const gpayPromoPageSeo = {
  title: 'Get 20% off on All Coding Courses with Google Pay',
  description:
    'Google Pay users can now get an exclusive 20% discount on all Codingal courses. Learn how to make your own apps, games and websites from the comfort of your home.',
  ...openGraphConfig(
    'https://www.codingal.com/gpay-promo/',
    'Get 20% off on All Coding Courses with Google Pay',
    'Google Pay users can now get an exclusive 20% discount on all Codingal courses. Learn how to make your own apps, games and websites from the comfort of your home.'
  ),
}

export const nationalCodingOlympiadPageSeo = {
  title:
    'National Coding Olympiad 2021: Online coding competitions for Grade 1 to 12 students',
  description:
    "Build apps, websites, and games at India's largest online coding competition for Grade 1 to 12 students and win prizes worth over 3 lakh. Register now for National Coding Olympiad 2021!",
  ...openGraphConfig(
    'https://www.codingal.com/national-coding-olympiad-2021/',
    'National Coding Olympiad 2021: Online coding competitions for Grade 1 to 12 students',
    "Build apps, websites, and games at India's largest online coding competition for Grade 1 to 12 students and win prizes worth over 3 lakh. Register now for National Coding Olympiad 2021!",
    'https://fastly.codingal.com/images/national-coding-olympiad/meta-image-v1.png'
  ),
}

export const nationalCodingSummit2021PageSeo = {
  title: 'National Coding Summit 2021: Code the future of India',
  description:
    "A unique opportunity for students, parents and teachers to meet and interact with India's leading educators, policymakers, and corporate leaders. Join us on July 15 to discover the evolution of ed-tech in India.",
  ...openGraphConfig(
    'https://www.codingal.com/national-coding-summit-2021/',
    'National Coding Summit 2021: Code the future of India',
    "A unique opportunity for students, parents and teachers to meet and interact with India's leading educators, policymakers, and corporate leaders. Join us on July 15 to discover the evolution of ed-tech in India.",
    'https://fastly.codingal.com/images/national-coding-summit-2021/meta-image.jpg'
  ),
}
export const phonePeCampaignSeo = {
  title: 'Get 20% off on All Coding Courses with PhonePe',
  description:
    'PhonePe users can now get an exclusive 20% discount on all Codingal courses. Learn how to make your own apps, games and websites from the comfort of your home.',
  ...openGraphConfig(
    'https://www.codingal.com/phonepe-promo/',
    'Get 20% off on All Coding Courses with PhonePe',
    'PhonePe users can now get an exclusive 20% discount on all Codingal courses. Learn how to make your own apps, games and websites from the comfort of your home.',
    'https://fastly.codingal.com/images/phonepe-campaign/meta-image.png'
  ),
}

export const freeClassV1Seo = {
  title: 'Free coding class for K-12 kids',
  description:
    'Try a free coding class. Learn basics of programming and start creating apps, games and websites today.',
  ...openGraphConfig(
    'https://www.codingal.com/lp/free-class-v1',
    'Free coding class for K-12 kids'
  ),
}

export const freeClassV2Seo = {
  title: 'Free coding class for K-12 kids',
  description:
    'Try a free coding class. Learn basics of programming and start creating apps, games and websites today.',
  ...openGraphConfig(
    'https://www.codingal.com/lp/free-class-v2',
    'Free coding class for K-12 kids'
  ),
}

export const famPayCampaignSeo = {
  title: 'Get 10% off on all coding courses with FamPay',
  description:
    'FamPay users can now get an exclusive 10% discount on all Codingal courses. Learn how to make your own apps, games and websites from the comfort of your home.',
  ...openGraphConfig(
    'https://www.codingal.com/fampay-promo/',
    'Get 10% off on all coding courses with FamPay',
    'FamPay users can now get an exclusive 10% discount on all Codingal courses. Learn how to make your own apps, games and websites from the comfort of your home.',
    'https://fastly.codingal.com/images/fampay-campaign/meta-image.png'
  ),
}

export const studentProjectPage = {
  title: 'Student coding projects to inspire you',
  description:
    'See all the amazing coding projects our students have created from exciting games and animations to complex apps and websites.',
  ...openGraphConfig(
    'https://www.codingal.com/student-coding-projects/',
    'Student coding projects to inspire you',
    'See all the amazing coding projects our students have created from exciting games and animations to complex apps and websites.',
    'https://fastly.codingal.com/images/student-projects-page/meta-image.png'
  ),
}

export const phoneDetailsCapture = {
  title: 'Free coding class for K-12 kids',
  description:
    'Try a free coding class. Learn basics of programming and start creating apps, games and websites today.',
  ...openGraphConfig(
    'https://www.codingal.com/lp/book-trial/',
    'Free coding class for K-12 kids',
    'Try a free coding class. Learn basics of programming and start creating apps, games and websites today.',
    'https://fastly.codingal.com/images/phone-detail-capture/meta-image.png'
  ),
}

export const bookTrialOfferSeo = {
  title: 'Get Rs2,000 off on coding courses for kids on Codingal',
  description:
    'Book an online coding class for your child today and watch them create amazing apps, websites, games, animations, and much more!',
  ...openGraphConfig(
    'https://www.codingal.com/lp/book-trial-offer/',
    'Get Rs2,000 off on coding courses for kids on Codingal',
    'Book an online coding class for your child today and watch them create amazing apps, websites, games, animations, and much more!',
    'https://fastly.codingal.com/images/book-trial-offer/meta-image.png'
  ),
}

export const teachPage = {
  title: 'Join as a coding teacher and start growing your income',
  description:
    'Teach coding to K-12 kids in 1:1 online classes and earn INR 50,000 per month. Flexible schedule and work from anywhere.',
  ...openGraphConfig(
    'https://www.codingal.com/teach/',
    'Join as a coding teacher and start growing your income',
    'Teach coding to K-12 kids in 1:1 online classes and earn INR 50,000 per month. Flexible schedule and work from anywhere.',
    'https://fastly.codingal.com/images/teacher-lp/meta-image.png'
  ),
}

export const learnCodingPageSeo = {
  title:
    'Try a free coding class for your child | Codingal is the #1 Coding Platform for kids',
  description:
    'Teach your child the fundamentals of coding. Your child can learn how to build animations, websites and mobile app from all-star teachers with computer science background. Book a class worth 799/- for free today.',
  ...openGraphConfig(
    'https://www.codingal.com/lp/learn-coding',
    'Try a free coding class for your child | Codingal is the #1 Coding Platform for kids',
    'Teach your child the fundamentals of coding. Your child can learn how to build animations, websites and mobile app from all-star teachers with computer science background. Book a class worth 799/- for free today.',
    'https://fastly.codingal.com/images/book-trial-class/meta-image.png'
  ),
}

export const masterCodingPage = {
  title:
    'Try a free coding class for your child | Codingal is the #1 Coding Platform for kids',
  description:
    'Teach your child the fundamentals of coding. Your child can learn how to build animations, websites and mobile app from all-star teachers with computer science background. Book a class worth 799/- for free today.',
  ...openGraphConfig(
    'https://www.codingal.com/lp/master-coding',
    'Try a free coding class for your child | Codingal is the #1 Coding Platform for kids',
    'Teach your child the fundamentals of coding. Your child can learn how to build animations, websites and mobile app from all-star teachers with computer science background. Book a class worth 799/- for free today.',
    'https://fastly.codingal.com/images/book-trial-class/meta-image.png'
  ),
}

export const bookTrialClassUAELandingPageSeo = {
  title: 'Try a free coding class for your child on Codingal',
  description:
    'Online coding classes to teach kids to build apps, games, and websites, and to learn the fundamentals of coding from all-star computer science teachers',
  ...openGraphConfig(
    'https://www.codingal.com/book-trial-ae/',
    'Try a free coding class for your child on Codingal',
    'Online coding classes to teach kids to build apps, games, and websites, and to learn the fundamentals of coding from all-star computer science teachers',
    'https://fastly.codingal.com/images/book-trial-class-uae/meta-image.png'
  ),
}
export const BookScratchTrialClassPageSeo = {
  title: 'Try a free Scratch programming classes for kids',
  description:
    'Online Scratch coding classes to teach kids to build games & animation using a block based with Scratch programming from all-star computer science teachers.',
  ...openGraphConfig(
    'https://www.codingal.com/lp/book-scratch-trial-class/',
    'Try a free Scratch programming classes for kids',
    'Online Scratch coding classes to teach kids to build games & animation using a block based with Scratch programming from all-star computer science teachers.',
    'https://fastly.codingal.com/images/book-trial-class/meta-image.png'
  ),
}
export const bookTrialClassInLandingPageSeo = {
  title: 'Try a free coding class for your child on Codingal',
  description:
    'Online coding classes to teach kids to build apps, games, and websites, and to learn the fundamentals of coding from all-star computer science teachers.',
  ...openGraphConfig(
    'https://www.codingal.com/book-trial-in/',
    'Try a free coding class for your child on Codingal',
    'Online coding classes to teach kids to build apps, games, and websites, and to learn the fundamentals of coding from all-star computer science teachers.',
    'https://fastly.codingal.com/images/book-trial-class-in/meta-image.png'
  ),
}
export const BookMentoringSessionLandingPageSeo = {
  title: 'Register for mentorship session for your child on Codingal',
  description:
    'Online coding classes to teach kids to build apps, games, and websites, and to learn the fundamentals of coding from all-star computer science teachers.',
  ...openGraphConfig(
    'https://www.codingal.com/lp/book-mentoring-session/',
    'Register for mentorship session for your child on Codingal',
    'Online coding classes to teach kids to build apps, games, and websites, and to learn the fundamentals of coding from all-star computer science teachers.',
    'https://fastly.codingal.com/images/landing-page/cp-og-image.jpg'
  ),
}
export const BookMentoringSessionIITB2023LandingPageSeo = {
  title: 'Register for mentorship session for your child on Codingal',
  description:
    'Online coding classes to teach kids to build apps, games, and websites, and to learn the fundamentals of coding from all-star computer science teachers.',
  ...openGraphConfig(
    'https://www.codingal.com/lp/book-mentoring-session/',
    'Register for mentorship session for your child on Codingal',
    'Online coding classes to teach kids to build apps, games, and websites, and to learn the fundamentals of coding from all-star computer science teachers.',
    'https://fastly.codingal.com/images/landing-page/cp-iitb-2023-og-image.jpg'
  ),
}

export const axisBankCreditCardPromoPage = {
  title: 'Get 20% off on All Coding Courses with Axis Bank Credit Card',
  description:
    'Axis Bank Credit Card users can now get an exclusive 20% discount on all Codingal courses. Learn to create your own apps, games and websites from your home.',
  ...openGraphConfig(
    'https://www.codingal.com/lp/master-coding',
    'Get 20% off on All Coding Courses with Axis Bank Credit Card',
    'Axis Bank Credit Card users can now get an exclusive 20% discount on all Codingal courses. Learn to create your own apps, games and websites from your home.',
    'https://fastly.codingal.com/images/book-trial-class/meta-image.png'
  ),
}

export const flipkartBigBillionSalePromoPage = {
  title:
    'Get 20% off on All Coding Courses for Kids with Flipkart Big Billion Sale',
  description:
    'Flipkart Big Billion Sale! Get an exclusive 20% discount on all coding courses for kids. Learn to create apps, games, and websites from the comfort of your home.',
  ...openGraphConfig(
    'https://www.codingal.com/lp/master-coding',
    'Get 20% off on All Coding Courses for Kids with Flipkart Big Billion Sale',
    'Flipkart Big Billion Sale! Get an exclusive 20% discount on all coding courses for kids. Learn to create apps, games, and websites from the comfort of your home.',
    'https://fastly.codingal.com/images/book-trial-class/meta-image.png'
  ),
}

export const competitionReferralPage = {
  title: 'Invite your friends to competitions and win exciting rewards',
  description:
    'Win drones, games, vouchers, and other exciting rewards by inviting your friends to compete in hackathons and coding challenges on Codingal',
}

export const preferencePageSeo = {
  title: '1-on-1 and/or Peer learning program student preference',
  description:
    'Congratulations on completing your first trial class, please select your preference for future classes!',
}

export const quizzesListSeo = {
  title: 'Fun coding quizzes for Grade 1-12 students',
  description:
    'Learn to code while having fun with coding quizzes for Grade 1-12 students | Show off your coding skills with real-time leaderboards | Take part in quiz championships and win amazing prizes.',
  ...openGraphConfig(
    'https://www.codingal.com/quizzes/',
    'Fun coding quizzes for Grade 1-12 students',
    'Learn to code while having fun with coding quizzes for Grade 1-12 students | Show off your coding skills with real-time leaderboards | Take part in quiz championships and win amazing prizes.'
  ),
}

export const boolTrialBdV1 = {
  title: 'Free coding class for K-12 kids',
  description:
    'Try a free coding class. Learn basics of programming and start creating apps, games and websites today.',
  ...openGraphConfig(
    'https://www.codingal.com/lp/book-trial-bd-v1/',
    'Free coding class for K-12 kids',
    'Try a free coding class. Learn basics of programming and start creating apps, games and websites today.',
    'https://fastly.codingal.com/images/phone-detail-capture/meta-image.png'
  ),
}

export const boolTrialIdV1 = {
  title: 'Free coding class for K-12 kids',
  description:
    'Try a free coding class. Learn basics of programming and start creating apps, games and websites today.',
  ...openGraphConfig(
    'https://www.codingal.com/lp/book-trial-id-v1/',
    'Free coding class for K-12 kids',
    'Try a free coding class. Learn basics of programming and start creating apps, games and websites today.',
    'https://fastly.codingal.com/images/phone-detail-capture/meta-image.png'
  ),
}

export const boolTrialVtV1 = {
  title: 'Free coding class for K-12 kids',
  description:
    'Try a free coding class. Learn basics of programming and start creating apps, games and websites today.',
  ...openGraphConfig(
    'https://www.codingal.com/lp/book-trial-vt-v1/',
    'Free coding class for K-12 kids',
    'Try a free coding class. Learn basics of programming and start creating apps, games and websites today.',
    'https://fastly.codingal.com/images/phone-detail-capture/meta-image.png'
  ),
}

export const boolTrialPhV1 = {
  title: 'Free coding class for K-12 kids',
  description:
    'Try a free coding class. Learn basics of programming and start creating apps, games and websites today.',
  ...openGraphConfig(
    'https://www.codingal.com/lp/book-trial-ph-v1/',
    'Free coding class for K-12 kids',
    'Try a free coding class. Learn basics of programming and start creating apps, games and websites today.',
    'https://fastly.codingal.com/images/phone-detail-capture/meta-image.png'
  ),
}

export const boolTrialNgV1 = {
  title: 'Free coding class for K-12 kids',
  description:
    'Try a free coding class. Learn basics of programming and start creating apps, games and websites today.',
  ...openGraphConfig(
    'https://www.codingal.com/lp/book-trial-ng-v1/',
    'Free coding class for K-12 kids',
    'Try a free coding class. Learn basics of programming and start creating apps, games and websites today.',
    'https://fastly.codingal.com/images/phone-detail-capture/meta-image.png'
  ),
}

export const boolTrialKeV1 = {
  title: 'Free coding class for K-12 kids',
  description:
    'Try a free coding class. Learn basics of programming and start creating apps, games and websites today.',
  ...openGraphConfig(
    'https://www.codingal.com/lp/book-trial-ke-v1/',
    'Free coding class for K-12 kids',
    'Try a free coding class. Learn basics of programming and start creating apps, games and websites today.',
    'https://fastly.codingal.com/images/phone-detail-capture/meta-image.png'
  ),
}

export const watchVideosOnThemes = {
  title: 'Watch videos on themes',
  description:
    'Our how-to videos help you understand the themes so you can build amazing projects that win the Hackathon.',
  ...openGraphConfig(
    'https://www.codingal.com/prepare-for-competitions/watch-videos-on-themes/',
    'Watch videos on themes',
    'Our how-to videos help you understand the themes so you can build amazing projects that win the Hackathon.',
    'https://fastly.codingal.com/images/national-coding-olympiad/meta-image-v1.png'
  ),
}

export const refresherVideos = {
  title: 'Quick refresher videos',
  description:
    'Refresh your coding knowledge with these informative concept-based videos hand-picked to help you win.',
  ...openGraphConfig(
    'https://www.codingal.com/prepare-for-competitions/refresher-videos/',
    'Quick refresher videos',
    'Refresh your coding knowledge with these informative concept-based videos hand-picked to help you win.',
    'https://fastly.codingal.com/images/national-coding-olympiad/meta-image-v1.png'
  ),
}

export const masterClasses = {
  title: 'Masterclasses for winners',
  description:
    'Watch our curated masterclasses to learn more about competitive coding and to sharpen your coding skills.',
  ...openGraphConfig(
    'https://www.codingal.com/prepare-for-competitions/masterclasses/',
    'Masterclasses for winners',
    'Watch our curated masterclasses to learn more about competitive coding and to sharpen your coding skills.',
    'https://fastly.codingal.com/images/prepare-for-competitions/masterclasses/meta-image.png'
  ),
}

export const freeWebsitesPlatforms = {
  title: 'Free websites and platforms',
  description:
    'Here are some of the best websites and platforms you can use to hone your coding skills and get ready to win.',
  ...openGraphConfig(
    'https://www.codingal.com/prepare-for-competitions/free-websites-platforms/',
    'Free websites and platforms',
    'Here are some of the best websites and platforms you can use to hone your coding skills and get ready to win.',
    'https://fastly.codingal.com/images/national-coding-olympiad/meta-image-v1.png'
  ),
}
export const boolTrialSgV1 = {
  title: 'Free coding class for K-12 kids',
  description:
    'Try a free coding class. Learn basics of programming and start creating apps, games and websites today.',
  ...openGraphConfig(
    'https://www.codingal.com/lp/book-trial-sg-v1/',
    'Free coding class for K-12 kids',
    'Try a free coding class. Learn basics of programming and start creating apps, games and websites today.',
    'https://fastly.codingal.com/images/phone-detail-capture/meta-image.png'
  ),
}

export const HpDiscountOffersPageSeo = {
  title: 'Get 20% off on All Coding Courses for Kids with HP',
  description:
    'HP users can now get an exclusive 20% discount on all Codingal courses. Learn to create your own apps, games, and websites from your home.',
  ...openGraphConfig(
    'https://www.codingal.com/lp/hp-discount-offers/',
    'Get 20% off on All Coding Courses for Kids with HP',
    'HP users can now get an exclusive 20% discount on all Codingal courses. Learn to create your own apps, games, and websites from your home.',
    `${process.env.NEXT_PUBLIC_STATIC_URL}/images/book-trial-class/meta-image.png`
  ),
}

export const partnerLandingPageSeo = (urlPath: string) => ({
  title:
    'Try a free coding class for your child | Codingal is the #1 Coding Platform for kids',
  description:
    'Let your child learn the fundamentals of coding. They can build animations, websites and mobile app with expert computer science teachers.',
  ...openGraphConfig(
    `https://www.codingal.com/${urlPath}/`,
    'Try a free coding class for your child | Codingal is the #1 Coding Platform for kids',
    'Let your child learn the fundamentals of coding. They can build animations, websites and mobile app with expert computer science teachers.',
    `${process.env.NEXT_PUBLIC_STATIC_URL}/images/book-trial-class/meta-image.png`
  ),
})

export const exclusiveCodingPackageSeo = {
  title: 'Exclusive Coding Package',
  description:
    'Curated handpicked concept-based masterclass, refresher videos, sessions & blogs to help kids build coding projects and find solutions.',
  ...openGraphConfig(
    'https://codingal.com/exclusive-coding-package/',
    'Exclusive Coding Package',
    'Curated handpicked concept-based masterclass, refresher videos, sessions & blogs to help kids build coding projects and find solutions.',
    `${process.env.NEXT_PUBLIC_STATIC_URL}/images/book-trial-class/meta-image.png`
  ),
}

export const scratchPrograming = {
  title: 'Best Online Scratch Coding Classes for Kids & Teens ',
  description: `Codingal offers fun online Scratch programming classes for kids & teens using the K12 Computer Science Framework. Sign up today to get a free class!`,
  ...openGraphConfig(
    'https://www.codingal.com/courses/scratch-programming/',
    'Best Online Scratch Coding Classes for Kids & Teens ',
    'Codingal offers fun online Scratch programming classes for kids & teens using the K12 Computer Science Framework. Sign up today to get a free class!',
    'https://fastly.codingal.com/images/courses/og/scratch-programming.png'
  ),
}
export const hpeCodewarsCodeBattleCourse = {
  title: 'Hpe CodeWars Code Battle Course',
  description: `Learn to ace the Code Battle competition with the custom course taught by our expert instructors.`,
  ...openGraphConfig(
    'https://www.codingal.com/courses/hpe-codewars-code-battle/',
    'Hpe CodeWars Code Battle Course',
    `Learn to ace the Code Battle competition with the custom course taught by our expert instructors.`,
    'https://fastly.codingal.com/images/courses/og/hpe-codewars-code-battle-v1.jpg'
  ),
}
export const HPECodewarsHackathonCourse = {
  title: 'Hpe CodeWars Hackathon Course',
  description: `Learn to ace the coding Hackathon with the custom course taught by our expert instructors.`,
  ...openGraphConfig(
    'https://www.codingal.com/courses/hpe-codewars-hackathon/',
    'Hpe CodeWars Hackathon Course',
    `Learn to ace the coding Hackathon with the custom course taught by our expert instructors.`,
    'https://fastly.codingal.com/images/courses/og/hpe-codewars-hackathon-v1.jpg'
  ),
}

// New SEO meta data setter. Use this for new additions.
// TODO: Update all the seo constants to use this function
type FormatSeoConfigType = {
  title: string
  description: string
  url: string
  metaImage?: string
}

export const formatSeoConfig = ({
  title,
  description,
  url,
  metaImage,
}: FormatSeoConfigType): NextSeoProps => {
  return {
    title,
    description,
    openGraph: {
      title,
      description,
      url,
      type: 'website',
      site_name: 'Codingal',
      images: [
        {
          url: metaImage || 'https://fastly.codingal.com/images/meta-image.png',
          alt: 'Codingal',
        },
      ],
    },
    twitter: {
      handle: '@codingal',
      cardType: 'summary_large_image',
    },
    facebook: {
      appId: '373752980313511',
    },
  }
}

export const pythonForKidsMetaData = formatSeoConfig({
  title: 'Best Online Python Coding Classes for Kids & Teens',
  description:
    'Codingal offers fun online Python programming classes for kids & teens using the K12 Computer Science Framework. Sign up today to get a free class!',
  url: 'https://www.codingal.com/courses/python-for-kids/',
  metaImage:
    'https://fastly.codingal.com/images/courses/og/python-for-kids.png',
})

export const USHomePageMetaData = formatSeoConfig({
  title: 'Online Coding Classes for Kids',
  description:
    'Personalized online coding classes for Kids. Try a free coding class and start creating your own apps, websites, games, and much more.',
  url: 'https://www.codingal.com/',
})

export const robloxMetaData = formatSeoConfig({
  title: 'Best Online Roblox Coding Classes for Kids & Teens',
  description:
    'Codingal offers fun online Roblox classes for kids & teens using the K12 Computer Science Framework. Sign up today to get a free class!',
  url: 'https://www.codingal.com/courses/roblox-coding/',
  metaImage: 'https://fastly.codingal.com/images/courses/og/roblox.jpg',
})

export const APComputerScienceA = formatSeoConfig({
  title: 'Best Online AP Computer Science A Classes ',
  description:
    'Codingal offers fun online AP Computer Science A classes for kids & teens using the K12 Computer Science Framework. Sign up today to get a free class!',
  url: 'https://www.codingal.com/courses/ap-computer-science-a/',
  metaImage:
    'https://fastly.codingal.com/images/courses/og/ap-computer-science-a.png',
})

export const PaymentPartnersMetaData = formatSeoConfig({
  title: 'Authorized payment partners',
  description:
    'Our official partners in different countries to make payment for courses.',
  url: 'https://www.codingal.com/payment-partners/',
})

export const refundPolicy = {
  title: 'Refund Policy | Learn About Codingal Refunds and Return Policy',
  description:
    'Codingal Refund Policy provides information on how refunds and cancellations are handled. It details the payment methods available and the time period for refunds.',
  url: 'https://www.codingal.com/refund-policy/',
}
export const systemCheck = {
  title: 'System Check: Test Your Computer Performance with Codingal',
  description:
    'Codingal System Check is a free online tool to help you quickly identify and troubleshoot any system issues. Get your system running smoothly today!',
  url: 'https://www.codingal.com/refund-policy/',
}
export const paymentPolicy = {
  title: 'Payment Policy | Secure Payment Solutions',
  description:
    'Learn payment policy of Codingal. We have many payment options & policies for students to ensure a hassle-free learning experience. Get all the necessary information here',
  url: 'https://www.codingal.com/refund-policy/',
}
